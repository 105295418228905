import React from 'react';
import PropTypes from 'prop-types';

import Accordion from './../Accordion';
import Button from './../Button';
import styles from './Faq.module.scss';

const Faq = ({ title = '', button = {}, items = [] }) => {
    const hasButton = !!button.title || !!button.href;

    return (
        <div className={styles['Faq']}>
            <div className={styles['Faq__Header']}>
                <div
                    className={styles['Faq__Title']}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                {hasButton && (
                    <div className={styles['Faq__Button']}>
                        <Button link={button} />
                    </div>
                )}
            </div>

            {items.map((item, index) => (
                <Accordion key={index} {...item} />
            ))}
            {hasButton && (
                <div className={styles['Faq__ButtonMobile']}>
                    {button && <Button link={button} />}
                </div>
            )}
        </div>
    );
};

Faq.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    button: PropTypes.shape({
        title: PropTypes.string,
        href: PropTypes.string,
        target: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            question: PropTypes.string,
            answer: PropTypes.string,
        })
    ),
};

export default Faq;
