import React, { useState, useId } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Accordion.module.scss';

const Accordion = ({ question = '', answer = '' }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const id = useId();
    const headerId = useId();

    const classes = classNames(styles['Accordion'], {
        [styles['Accordion--Expanded']]: isExpanded,
    });

    const handleClick = (expanded, question) => {
        setIsExpanded(expanded);

        window._mtm = window._mtm || [];
        window._mtm.push({
            faqTitle: question,
            event: !isExpanded
                ? 'customerServiceExpandFaqQuestion'
                : 'customerServiceMinimizeFaqQuestion',
        });
    };

    return (
        <div className={classes}>
            <button
                className={styles['Accordion__Button']}
                onClick={() => handleClick(!isExpanded, question)}
                aria-expanded={isExpanded}
                aria-controls={id}
                id={headerId + '-header'}>
                {question}
            </button>
            <div className={styles['Accordion__Panel']}>
                <div className={styles['Accordion__Content']}>
                    <div
                        className={styles['Accordion__RichText']}
                        aria-labelledby={headerId + '-header'}
                        aria-hidden={!isExpanded}
                        id={id}
                        dangerouslySetInnerHTML={{ __html: answer }}
                    />
                </div>
            </div>
        </div>
    );
};

Accordion.propTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
};

export default Accordion;
